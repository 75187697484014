import OpenAI from 'openai';
import { fal } from '@fal-ai/client';
import { supabase } from '../lib/supabase';
import { getOrCreateSession } from '../lib/session';

// Fetch API keys from the environment variables
const openAiApiKey = import.meta.env.VITE_OPENAI_API_KEY;
const pixelixeApiKey = import.meta.env.VITE_PIXELIXE_API_KEY;
const FAIApiKey = import.meta.env.VITE_FAI_API_KEY;

const openai = new OpenAI({
  apiKey: openAiApiKey,
  dangerouslyAllowBrowser: true
});

// Configure Fal AI if key exists.
if (FAIApiKey) {
  fal.config({ credentials: FAIApiKey });
}

/** Logging Helpers */
function logApiDetails(stage, details) {
  console.group(`API [${stage}] - ${new Date().toISOString()}`);
  console.log('Details:', details);
  console.groupEnd();
}

function logApiError(stage, error) {
  console.group(`❌ API Error [${stage}] - ${new Date().toISOString()}`);
  console.error('Error Details:', error);
  console.groupEnd();
}

/** Utility: Convert Blob to base64 (without header) */
function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      const base64 = typeof result === "string" ? result.split(',')[1] : "";
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

/** Utility: Convert data URI to Blob */
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

/** Upload an image (base64 without header) to Supabase Storage and return its public URL */
async function uploadImage(base64Data) {
  const filename = `image-${Date.now()}.jpg`;
  const dataURI = `data:image/jpeg;base64,${base64Data}`;
  const blob = dataURItoBlob(dataURI);
  const { error: uploadError } = await supabase.storage
    .from('images')
    .upload(filename, blob, { contentType: 'image/jpeg' });
  if (uploadError) throw uploadError;

  const { data: urlData, error: urlError } = supabase.storage
    .from('images')
    .getPublicUrl(filename);
  if (urlError) throw urlError;
  if (!urlData || !urlData.publicUrl) {
    throw new Error("Public URL not found for uploaded image");
  }
  return urlData.publicUrl;
}

/** Crop an image using the Pixelixe Crop API.
 * The imageUrl must be publicly accessible.
 * This function now includes the correct parameter "imageType=jpeg" per the documentation.
 */
async function cropImage(imageUrl, width, height, x, y) {
  const cropEndpoint = "https://studio.pixelixe.com/api/crop/v1";
  const cropUrl = `${cropEndpoint}?width=${width}&height=${height}&x=${x}&y=${y}&imageUrl=${encodeURIComponent(imageUrl)}&imageType=jpeg`;
  
  const response = await fetch(cropUrl, {
    headers: { "Authorization": `Bearer ${pixelixeApiKey}` }
  });
  if (!response.ok) {
    console.error("Crop URL:", cropUrl);
    throw new Error("Crop API error: " + response.statusText);
  }
  return await response.blob();
}

/** Parse crop coordinates from OpenAI's text output.
 * Expected JSON format: {"width":30,"height":15,"x":35,"y":50}
 */
function parseCropCoordinates(text) {
  // Remove markdown formatting (if any)
  const cleanedText = text.replace(/```(json)?/gi, '').trim();
  try {
    const obj = JSON.parse(cleanedText);
    ['width', 'height', 'x', 'y'].forEach(key => {
      if (typeof obj[key] !== 'number') {
        throw new Error(`Invalid type for ${key}`);
      }
    });
    return obj;
  } catch (err) {
    throw new Error("Could not parse crop coordinates: " + err.message);
  }
}

/** Parse the detailed smile analysis text response. */
function parseTextResponse(text) {
  console.log('Parsing analysis response text:', text);
  const sections = text.split('\n\n').filter(Boolean);
  const result = {};

  const treatmentIndex = sections.findIndex(section => section.trim().startsWith('Treatment:'));
  if (treatmentIndex !== -1) {
    result.treatment = sections[treatmentIndex].replace(/^Treatment:\s*/i, '').trim();
  }
  const promptIndex = sections.findIndex(section => section.trim().startsWith('Prompt:'));
  if (promptIndex !== -1) {
    result.prompt = sections[promptIndex].replace(/^Prompt:\s*/i, '').trim();
  }

  sections.forEach(section => {
    const lines = section.split('\n').map(line => line.trim());
    const scoreMatch = lines[0]?.match(/^(Colour|Shape|Spacing|Alignment):\s*(\d+)/i);
    if (scoreMatch) {
      const category = scoreMatch[1].toLowerCase();
      const score = parseInt(scoreMatch[2], 10);
      const bulletPoints = lines.slice(1).filter(line => line.startsWith('+') || line.startsWith('-')).join('\n');
      const description = bulletPoints ||
        lines.find(line => line.startsWith('Description:'))?.replace(/^Description:\s*/i, '') ||
        'Analysis not available';
      result[category] = { score: Math.min(Math.max(score, 0), 100), description };
    }
  });

  return {
    colour: result.colour || { score: 0, description: 'Analysis not available' },
    shape: result.shape || { score: 0, description: 'Analysis not available' },
    spacing: result.spacing || { score: 0, description: 'Analysis not available' },
    alignment: result.alignment || { score: 0, description: 'Analysis not available' },
    treatment: result.treatment || 'Treatment recommendation not available',
    prompt: result.prompt
  };
}

/**
 * Main function for analyzing a smile image.
 * Process:
 * 1. Load the original image to get its dimensions.
 * 2. Request crop coordinates from OpenAI with a prompt that returns only JSON.
 * 3. Convert percentage values to pixel coordinates.
 * 4. Crop the image using the Pixelixe API.
 * 5. Analyze the cropped image using OpenAI.
 * 6. Optionally, process the cropped image with Fal AI for image-to-image transformation.
 */
export async function analyzeSmileImage(
  imageBase64,
  firstName,
  age,
  gender,
  budget,
  email,
  location,
  concerns
) {
  try {
    logApiDetails('Starting Analysis', {
      hasImage: !!imageBase64,
      imageSize: imageBase64.length,
      budget,
      hasEmail: !!email,
      hasLocation: !!location,
      concerns
    });

    // Remove the base64 header.
    const cleanBase64 = imageBase64.replace(/^data:image\/\w+;base64,/, '');

    // Step 1: Load the original image to determine its dimensions.
    const img = new Image();
    img.src = `data:image/jpeg;base64,${cleanBase64}`;
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = () => reject(new Error("Error loading image for dimension conversion"));
    });
    const actualWidth = img.naturalWidth;
    const actualHeight = img.naturalHeight;
    logApiDetails('Image Dimensions', { width: actualWidth, height: actualHeight });

    // Step 2: Request crop coordinates from OpenAI.
    const cropCoordPayload = {
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: `You are an expert image analysis assistant. Given an input selfie image, determine a broad bounding box that captures the subject’s smile area (including some margin above and below the mouth) in the center.
Respond ONLY with a valid JSON object on a single line containing exactly four keys: "width", "height", "x", and "y". Each value must be a number representing the percentage (0 to 100) of the image dimensions. Do not include any additional text or commentary. Increase the bounding box dimensions by 30% for extra margin.`
        },
        {
          role: "user",
          content: [
            { type: "text", text: "Analyze this selfie and provide the crop coordinates." },
            { type: "image_url", image_url: { url: `data:image/jpeg;base64,${cleanBase64}` } }
          ]
        }
      ],
      max_tokens: 100
    };

    const coordResponse = await openai.chat.completions.create(cropCoordPayload);
    const coordText = coordResponse.choices[0]?.message?.content;
    if (!coordText) throw new Error("No crop coordinates received from OpenAI");
    logApiDetails('Crop Coordinates Received', { coordText });
    const percentCoords = parseCropCoordinates(coordText);

    // Step 3: Convert percentage values to pixel coordinates.
    const widthPx = Math.round((percentCoords.width / 100) * actualWidth);
    const heightPx = Math.round((percentCoords.height / 100) * actualHeight);
    const xPx = Math.round((percentCoords.x / 100) * actualWidth);
    const yPx = Math.round((percentCoords.y / 100) * actualHeight);
    logApiDetails('Converted Crop Coordinates', { pixelCoordinates: { width: widthPx, height: heightPx, x: xPx, y: yPx } });

    // Step 4: Crop the image using the Pixelixe API.
    const originalImageUrl = await uploadImage(cleanBase64);
    const croppedBlob = await cropImage(originalImageUrl, widthPx, heightPx, xPx, yPx);
    const croppedBase64 = await blobToBase64(croppedBlob);
    logApiDetails('Image Cropped', { cropDimensions: { width: widthPx, height: heightPx, x: xPx, y: yPx } });
    console.log("Cropped Image (Base64):", croppedBase64);

    // Step 5: Send the cropped image to OpenAI for detailed smile analysis.
    const smileAnalysisPayload = {
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: `You are a dental expert. Analyze this cropped smile image and provide concise scores (between 60 and 95) with short descriptions for Colour, Shape, Spacing, and Alignment. Then, give a one-sentence treatment recommendation and an AI prompt for enhancing the smile.
Format your response exactly as follows:

Colour: [score]
Description:
+ [positive point]
- [area for improvement]
- [area for improvement]

Shape: [score]
Description:
+ [positive point]
- [area for improvement]
- [area for improvement]

Spacing: [score]
Description:
+ [positive point]
- [area for improvement]
- [area for improvement]

Alignment: [score]
Description:
+ [positive point]
- [area for improvement]
- [area for improvement]

Treatment: [one-sentence recommendation]
Prompt: [AI prompt for image-to-image transformation which enhances smile appearance.]`
        },
        {
          role: "user",
          content: [
            { type: "text", text: "Please analyze this cropped smile image." },
            { type: "image_url", image_url: { url: `data:image/jpeg;base64,${croppedBase64}` } }
          ]
        }
      ],
      max_tokens: 500
    };

    const smileResponse = await openai.chat.completions.create(smileAnalysisPayload);
    const smileAnalysisText = smileResponse.choices[0]?.message?.content;
    if (!smileAnalysisText) throw new Error("No analysis received from OpenAI for the smile image");
    logApiDetails('Smile Analysis Received', { smileAnalysisText });
    const analysisResult = parseTextResponse(smileAnalysisText);

    // Step 6: Optionally, process the cropped image with Fal AI for image-to-image transformation.
    let falResult = null;
    let outputImageUrl = undefined;
    if (FAIApiKey && analysisResult.prompt) {
      const falSubmit = await fal.queue.submit("fal-ai/flux/dev/image-to-image", {
        input: {
          image_url: `data:image/jpeg;base64,${croppedBase64}`,
          prompt: "Enhance the smile by whitening and straightening the teeth, filling any gaps and reducing crowding while keeping all other aspects intact. Please ensure that: The overall image style remains unchanged. The skin colour is preserved. The shape and appearance of the lips are not modified.",
          strength: 0.6
        }
      });
      let statusResponse = await fal.queue.status("fal-ai/flux/dev/image-to-image", {
        requestId: falSubmit.request_id,
        logs: true
      });
      while (statusResponse.status !== "COMPLETED") {
        if (statusResponse.logs && Array.isArray(statusResponse.logs)) {
          statusResponse.logs.forEach(log => console.log(log.message));
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
        statusResponse = await fal.queue.status("fal-ai/flux/dev/image-to-image", {
          requestId: falSubmit.request_id,
          logs: true
        });
      }
      const falResultResponse = await fal.queue.result("fal-ai/flux/dev/image-to-image", {
        requestId: falSubmit.request_id
      });
      falResult = falResultResponse;
      if (falResultResponse.data?.images?.length > 0) {
        outputImageUrl = falResultResponse.data.images[0].url;
        logApiDetails('Fal AI Image URL', { outputImageUrl });
      }
    }

    // Return the analysis results and cropped image details.
    return {
      analysis: analysisResult,
      falResult,
      imageUrl: outputImageUrl,
      croppedImageBase64: croppedBase64
    };

  } catch (error) {
    logApiError('Analysis', error);
    if (error?.code === 'model_not_found') {
      throw new Error("The AI model is currently unavailable. Please try again later.");
    } else if (error?.code === 'context_length_exceeded') {
      throw new Error("The image is too complex to analyze. Please try a different photo.");
    } else if (error?.status === 429) {
      throw new Error("Too many requests. Please wait a moment and try again.");
    } else if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error("An unexpected error occurred during analysis. Please try again.");
  }
}

// Export the AnalysisResult type.
export interface AnalysisResult {
  colour: {
    score: number;
    description: string;
  };
  shape: {
    score: number;
    description: string;
  };
  spacing: {
    score: number;
    description: string;
  };
  alignment: {
    score: number;
    description: string;
  };
  treatment: string;
  prompt?: string;
}
